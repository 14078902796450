.header2 {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  min-height: 25px;
  background-color: rgb(45, 53, 56);
  display: flex;
  justify-content: space-evenly;
  padding-top: 70px;
}

.header2-nav {
  position: absolute;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.header2-nav li {
display: inline-block;
padding: 15px 20px;
text-decoration: none;
background-color: rgb(228, 69, 69);
border-right: solid 1px;
text-align: center;
}

.header2-nav li a{
  color: white;
  padding: 5px;
  text-decoration: none;
}

.header2-nav li a:hover{
  color: lightseagreen;
  padding: 5px;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .header2 {
    min-height: 20px;
    padding-top: 60px;
  }

  .header2-nav {
    font-weight: 400;
    font-size: small;
  }

  .header2-nav li {
    padding: 15px;
    text-align: center;
    }
}

@media (max-width: 767px) {
  .header2 {
    min-height: 15px;
    padding-top: 50px;
  }

  .header2-nav {
    font-weight: 400;
    font-size: small;
  }

  .header2-nav li {
    padding: 10px;
    text-align: center;
    }
}

@media (max-width: 480px) {
  .header2 {
    min-height: 8px;
    padding-top: 45px;
  }

  .header2-nav {
    font-weight: 400;
    font-size: xx-small;
  }

  .header2-nav li {
    padding: 3px 0;
    }
}