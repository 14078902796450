.main-div {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  box-sizing: border-box;
}

#services, #about, #contacts, #mk, #tb, #bv, #reh {
  position: relative;
  top: -70px;
}

.main-img {
  height: 515px;
  max-width: 1600px;
  margin: 0 auto;
  background-image: url(./Img/main.jpg);
  }

.main-img h1 {
  width: 55%;
  text-align: center;
  padding-top: 5em;
  margin: 0 auto;
  color: white;
  font-size:xx-large;
  line-height: 1,5em;
}

.main-services, .main-about, .main-contacts {
color: rgb(121, 122, 122);
font-size: larger;
text-align: center;
padding: 5px;
}

.div-line {
  height: 3px;
  width: 30%;
  background-color: turquoise;
  margin: 0 auto;
}

.content-block {
display: flex;
flex-wrap: wrap;
margin: 10px auto;
}

.main-content {
  box-sizing: border-box;
  align-items: center;
  max-width: 48%;
  padding: 10px 30px;
  margin: 15px auto;
  background: rgb(236, 236, 236);
  border-radius: 10px;
}

.main-content h2{
  text-align: center;
}

.main-content > div >img{
  text-align: center;
  width: 100%;
  border-radius: 10px;
}

.main-content a {
  background-color: lightseagreen;
  color: white;
  border-radius: 15px;
  padding: 5px 20px;
  text-decoration: none;
  text-align: right;
  margin-bottom: 10px;
  float: right;
  border: solid 1px;
}

.main-content a:hover {
  color: lightseagreen;
  border: solid 1px;
  background-color: rgb(236, 236, 236);
}

.advantages {
background-color: rgb(72, 70, 80);
margin: 0 auto;
padding: 20px;
color: white;
font-size: large;
}

.advantages h2 {
  text-align: center;
  color: white;
  font-size: xx-large;
}

.advantages-box {
  display: flex;
  margin: 0 auto;
  width: 100%;
}

.advantages-col {
  margin: 0 auto;
  padding-left: 25px;
}

.advantages-col h3{
  text-align: left;
  padding-left: 20px;
}

.about-img{
  min-height: 399px;
  margin: 0 auto;
  background-image: url(./Img/zavod.jpg);
}

.about-text {
  box-sizing: border-box;
  border: solid 1px gray;
  background: rgb(236, 236, 236);
  border-radius: 10px;
  width: 95%;
  margin: 30px auto;
  padding: 10px 30px;
  display: flex;
}

.about-text-col {
  width: 32%;
  padding-left: 10px;
}

.mk-block, .tb-block, .bv-block, .reh-block {
  width: 95%;
  margin: 30px auto;
}

.mk-block h2, .tb-block h2, .bv-block h2, .reh-block h2 {
  text-align: center;
  color: rgb(255, 255, 255);
  background-color: rgb(228, 69, 69);
  padding: 10px 0;
  margin: 20px auto;
  width: 100%;
}

.mk-block > h2::before, .mk-block > h2::after,  .tb-block > h2::before, .tb-block > h2::after,  .bv-block > h2::before, .bv-block > h2::after, .reh-block > h2::before, .reh-block > h2::after {
  content: '\2237';
  padding: 2px;
  margin: 0 5px;
  color: rgb(255, 255, 255);
}

.mk-block-text, .tb-block-text, .bv-block-text, .reh-block-text {
  border: solid 1px gray;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px 30px;
  background: rgb(236, 236, 236);
}

.img-left {
  text-align: center;
  width: 100%;
  max-width: 700px;
  float: left;
  padding: 0 15px 10px 0;
}
.img-right {
  text-align: center;
  width: 100%;
  max-width: 700px;
  float: right;
  padding: 0 0 10px 15px;
}

.contacts-block {
  width: 100%;
  margin: 10px auto;
  display: flex;
}

.contacts-block-col {
  width: 45%;
  margin: 10px auto;
  border: solid 1px gray;
  border-radius: 10px;
  background: rgb(236, 236, 236);
  padding: 0 30px;
}

 
@media (max-width: 1024px) {
  .main-img {
    height: 330px;
    background-image: url(./Img/main-2.jpg);
    }

    .main-img h1 {
      width: 65%;
      padding-top: 4em;
      font-size:x-large;
      line-height: 1,5em;
    }

    .main-services, .main-about, .main-contacts {
      color: rgb(121, 122, 122);
      font-size: medium;
      text-align: center;
      padding: 0;
      }

    .main-content {
      box-sizing: border-box;
      align-items: center;
      max-width: 95%;
      padding: 10px 20px;
      margin: 10px auto;
      background: rgb(236, 236, 236);
      border-radius: 10px;
    }

    .advantages {
    margin: 0 auto;
    padding: 10px;
    font-size: medium;
    }

    .advantages h2 {
      font-size: x-large;
    }

    .advantages-col {
      margin: 0 auto;
      padding-left: 15px;
    }

    .advantages-col h3{
      padding-left: 0;
    }

    .about-img{
      min-height: 255px;
      background-image: url(./Img/zavod-2.jpg);
    }

    .about-text {
      margin: 20px auto;
      padding: 10px;
    }

    .mk-block, .tb-block, .bv-block, .reh-block {
      margin: 20px auto;
    }

    .mk-block-text, .tb-block-text, .bv-block-text, .reh-block-text {
      padding: 0 20px;
    }

    .contacts-block {
      margin: 10px auto;
      display: flex;
align-items: center;      
    }
    
    .contacts-block-col {
      box-sizing: border-box;
      margin: 10px auto;
      padding: 0 10px;
    }
}

@media (max-width: 767px) {
  .main-img {
    height: 245px;
    background-image: url(./Img/main-3.jpg);
    }

    .main-img h1 {
      padding-top: 4em;
      font-size:medium;
      line-height: 1,5em;
    }

    .main-services, .main-about, .main-contacts {
      font-size: medium;
      }

      .div-line {
        height: 2px;
        width: 40%;
      }
      
      .content-block {
      margin: 5px auto;
      }

    .main-content {
      padding: 0 10px;
      margin: 5px auto;
    }

  .main-content h2{
    font-size: larger;    
  }

  .main-content a {
    padding: 4px 10px;
  }

  .advantages {
    padding: 10px;
    font-size: medium;
    }

  .advantages h2 {
      font-size: large;
    }

  .advantages-box {
      display: unset;
      }

  .advantages-col {
      margin: 0 auto;
      padding-left: 20px;
    }

  .advantages-col h3{
      padding-left: 10px;
      font-size: medium;
    }

  .about-img{
      min-height: 191px;
      background-image: url(./Img/zavod-3.jpg);
    }

  .about-text {
      margin: 10px auto;
      padding: 0 10px;
      flex-direction: column;
    }

  .about-text-col {
      width: 95%;
    }

  .mk-block, .tb-block, .bv-block, .reh-block {
      margin: 20px auto;
    }

  .mk-block-text, .tb-block-text, .bv-block-text, .reh-block-text {
      padding: 0 10px;
    }

  .mk-block h2, .tb-block h2, .bv-block h2, .reh-block h2 {
      text-align: center;
      font-size: medium;
      padding: 10px 0;
      margin: 10px auto;
    }

    .contacts-block {
      margin: 10px auto;
      display: flex;
      flex-wrap: wrap;
  
    }
    
    .contacts-block-col {
      box-sizing: border-box; 
      min-height: 320px;
      width: 95%;
      margin: 10px auto;
      padding: 0 10px;
    }
}

@media (max-width: 480px) {
  .main-img {
    height: 245px;
    background-image: url(./Img/main-3.jpg);
    }

    .about-img{
      min-height: 200px;
      background-image: url(./Img/zavod-4.jpg);
    }



}

