* {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 20px 0;
}

p {
  margin: 15px 0;
}

@media (max-width: 1024px) {
  h1, h2, h3, h4, h5, h6 {
    margin: 15px 0;
  }
}

@media (max-width: 767px) {
  h1, h2, h3, h4, h5, h6 {
    margin: 15px 0;
  }
}

@media (max-width: 480px) {
  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
  }
}