.header {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  background-color: rgb(45, 53, 56);
  display: flex;
  justify-content: space-between;
}

.logo-header {
  width: 200px;
  height: 70px;
  margin-left: 20px;
  background: url(./logo.svg);
}

.header-block-right {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  margin-right: 20px;
}

.header-nav {
  margin-right: 15px;
  font-size: large;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.header-nav li {
  display: inline-block;
  padding: 8px;
}

.header-nav li a{
  color: white;
  padding: 5px;
  text-decoration: none;
}

.header-nav li a:hover{
  color: lightseagreen;
  padding: 5px;
  text-decoration: underline;
}

.mob-menu {
  display: flex;
}

.header-form {
  min-width: 100px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.header-form a{
  background-color: lightseagreen;
  color: white;
  border-radius: 15px;
  padding: 5px 10px;
  text-decoration: none;
  border: solid 1px;
  border-color: lightseagreen;
}

.header-form a:hover{
  color: lightseagreen;
  border: solid 1px;
  background-color: rgb(45, 53, 56);
}

.header-phone a{
  font-weight: 500;
  font-size: medium;
  text-decoration: none;
  color: white;
  margin-left: 20px;
  text-decoration: none;
  }

  .header-phone a:hover {
  color: lightseagreen;
}


@media (max-width: 1024px) {
  .header {
    height: 60px;
  }

  .logo-header {
    margin-left: 10px;
    height: 60px;
    width: 170px;
    background: url(./logo2.svg);
  }
  
  .header-nav {
    margin-right: 5px;
    font-size:small;
  }

  .header-block-right {
    margin-right: 15px;
    height: 60px;
  }

  .header-nav li {
    padding: 5px;
  }

  .header-form {
    min-width: 30px;
  }

  .header-form a{
    border-radius: 15px;
    padding: 5px;
    font-size: small;
  }

  .header-phone a{
    font-size: small;
    margin-left: 10px;
    }
    .mob-menu {
      display: flex;
    }
}

@media (max-width: 767px) {
  .header {
    height: 50px;
  }

  .logo-header {
    margin-left: 5px;
    height: 47px;
    width: 137px;
    background: url(./logo3.svg);
  }
  
  .header-nav {
    margin-right: 5px;
    font-size:small;
  }

  .header-block-right {
    margin-right: 10px;
    height: 50px;
  }

  .header-nav li {
    padding: 3px;
  }

  .header-form {
    min-width: 30px;
  }

  .header-form a{
    border-radius: 15px;
    padding: 3px;
    font-size: x-small;
  }

  .header-phone a{
    font-size: small;
    margin-left: 5px;
    }
    .mob-menu {
      display: flex;
    }
}

@media (max-width: 480px) {
  .header {
    height: 45px;
  }

  .logo-header {
    margin-left: 3px;
    height: 40px;
    width: 116px;
    background: url(./logo4.svg);
  }
  
  .header-nav {
    margin-right: 0;
    font-size:xx-small;
    min-width: 200px;
  }

  .header-block-right {
    margin-right: 0;
    min-height: 40px;
    justify-content: space-evenly;

  }

  .header-nav li {
    padding: 3px;
  }

  .header-form {
    min-width: 0;
    margin-right: 10px;
  }

  .header-form a{
    border-radius: 15px;
    padding: 1px 2px;
    font-size: xx-small;
    height: 18px;

  }

  .header-phone a{
    font-size: xx-small;
    margin-right: 5px;
    height: 19px;
    }

    .header-block-right {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 20px;
      margin: 0;
    }

    .mob-menu {
      display: flex;
      height: 20px;
      margin: 0;
    }
}