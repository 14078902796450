.footer {
  background-color: rgb(48, 48, 48);
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  color: white;
}

.footer-container {
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logo-footer {
background: url(./redlogo.svg);
height: 67px;
width: 197px;
}

.footer p {
  padding: 0 20px;
}


@media (max-width: 1024px) {
  .logo-footer {
    background: url(./redlogo-2.svg);
    height: 40px;
    width: 116px;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 480px) {
  .footer {
font-size: small;
  }

  .footer-container {
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .footer p {
    padding: 0 10px;
  }
}